<template>
  <b-row>
    <b-col md="12">
      <div class="search-wrapper">
        <b-input-group class="mb-1 search-input-group flex-wrap">
          <!-- Search Input -->
          <div class="position-relative w-100 mb-0 mb-md-0">
            <b-form-input 
              class="search-input pr-4" 
              placeholder="Enter your prompt in details to start generating... " 
              v-model="searchQuery"
            ></b-form-input>
            <div 
              class="idea-icon-wrapper"
              @click="suggestPrompt"
              v-b-tooltip.hover.right
              title="Get prompt suggestion"
            >
              <img 
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9ImN1cnJlbnRDb2xvciIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIGNsYXNzPSJsdWNpZGUgbHVjaWRlLWxpZ2h0YnVsYiI+PHBhdGggZD0iTTE1IDE0Yy4yLTEgLjctMS43IDEuNS0yLjUgMS0uOSAxLjUtMi4yIDEuNS0zLjVBNiA2IDAgMCAwIDYgOGMwIDEgLjIgMi4yIDEuNSAzLjUuNy43IDEuMyAxLjUgMS41IDIuNSIvPjxwYXRoIGQ9Ik05IDE4aDYiLz48cGF0aCBkPSJNMTAgMjJoNCIvPjwvc3ZnPg=="
                alt="Idea icon"
                class="idea-icon"
              />
            </div>
          </div>
          <!-- Swiper Component -->
      <swiper 
        v-if="showSwiper" 
        :options="swiperOptions" 
        class="swiper-container mt-0"
      >
        <swiper-slide 
          v-for="(tab, index) in tabs" 
          :key="index" 
          class="ss-width"
        >
          <b-button 
            :variant="tab.active ? 'dark' : 'outline-secondary'" 
            @click="selectTab(index)" 
            class="tab-button"
          >
            {{ tab.name }}
          </b-button>
        </swiper-slide>
      </swiper>
          
          <!-- Buttons Group -->
<div class="button-wrapper w-100 mt-1 w-md-auto d-flex">
  <b-input-group-append class="w-100 w-md-auto d-flex">
    <b-button 
      v-show="!isDeleteMode"
      variant="primary" 
      @click="searchInput" 
      :disabled="generating" 
      class="flex-grow-1 flex-md-grow-0 button-margin me-2 upload-btn"
    >
      <feather-icon size="13" icon="ZapIcon" />
      {{ generating ? 'Generating' : 'Generate' }}
    </b-button>
    
    <!-- Bulk Delete Controls -->
    <b-button
      v-show="isDeleteMode"
      variant="danger"
      @click="confirmDelete"
      class="flex-grow-1 flex-md-grow-0 button-margin me-2"
      :disabled="selectedImagesCount === 0"
    >
      <feather-icon size="13" icon="TrashIcon" />
      Delete Selected ({{ selectedImagesCount }})
      <span v-if="isAdmin" class="ms-1 admin-badge">Admin</span>
    </b-button>
  </b-input-group-append>
  
  <b-button 
    :variant="isDeleteMode ? 'outline-secondary' : 'secondary'"
    @click="toggleBulkDelete"
    class="flex-grow-0"
    v-b-tooltip.hover
    :title="isDeleteMode ? 'Cancel Selection' : 'Select Images to Delete'"
  >
    <feather-icon size="13" :icon="isDeleteMode ? 'XIcon' : 'TrashIcon'" />
  </b-button>
</div>
        </b-input-group>
      </div>
      <!-- Info Line -->
      <div
        v-if="generating"
        class="info-line d-flex align-items-center justify-content-between flex-wrap mt-1"
      >
      <div class="d-flex align-items-center flex-grow-1">
  <feather-icon icon="InfoIcon" class="info-icon me-2" />
  <span class="note-text">
      If images aren't good, try different prompts for better results. 
      Click <feather-icon icon="MessageCircleIcon" class="like-icon" /> on an image to create social media content. 
      Click <feather-icon icon="HeartIcon" class="like-icon" /> to save to favorites.
    </span>
</div>


        <!-- <b-button
          variant="link"
          @click="hideInfoLine"
          class="close-button ms-2"
        >
          <feather-icon icon="XIcon" />
        </b-button> -->
      </div>

      

    </b-col>
  </b-row>
</template>


<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';
import { BFormSelect } from 'bootstrap-vue';
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { EventBus } from '@/event-bus';
import axios from 'axios'

export default {
  props: {
  showSwiper: {
    type: Boolean,
    default: true
  },
  prompts: {
    type: Array,
    default: () => []
  },
  isDeleteMode: {
    type: Boolean,
    default: false
  },
  selectedImagesCount: {
    type: Number,
    default: 0
  },
  isAdmin: {
    type: Boolean,
    default: false
  }
},
  components: {
    Swiper,
    SwiperSlide,
    BFormSelect,
    ToastificationContent,
  },
  data() {
    return {
      searchQuery: '',
      selectedImageType: 'illustrations', // Default image type
      imageTypes: [
        { value: 'images', text: 'Images' },
        { value: 'memes', text: 'Memes' },
        { value: 'quotes', text: 'Quotes' },
        { value: 'illustrations', text: 'Illustrations' }
      ],
      tabs: [
        { name: 'Comic book', active: true },
        { name: 'Pixar/Disney Character', active: false },
        { name: 'Line art', active: false },
        { name: 'Black and White Film Noir', active: false },
        { name: 'Japanese Anime', active: false },
        { name: 'Digital/Oil Painting', active: false },
        // { name: 'Photographic', active: false },
        { name: 'Isometric Rooms', active: false },
      ],
      generating: false, 
      showInfoLine: false, 
      settings: {
                maxScrollbarLength: 100,
                wheelSpeed: .60
            },
      swiperOptions: {
        slidesPerView: 'auto',
        spaceBetween: 1,
        observer: true,
        observeParents: true,
        observeSlideChildren: true,
        resistanceRatio: 0.85,
    watchOverflow: true,
    updateOnWindowResize: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        breakpoints: {
          1024: {
                        slidesPerView: 'auto',
                        spaceBetween: 5
                    },
                    768: {
                        slidesPerView: 'auto',
                        spaceBetween: 5
                    },
                    640: {
                        slidesPerView: 'auto',
                        spaceBetween: 5
                    },
          320: {
            slidesPerView: 'auto',
            spaceBetween: 5,
          },
        },
      },
      selectedTab: 'Comic book',
      requestCount: 0, 
      maxRequests: 3, 
      isLogged: false, 
      currentPromptIndex: 0,
      currentSeed: '',
    };
  },

  watch: {
    $route(to) {
      this.updateSelectedImageType(to);
    }
  },
  mounted() {
    this.updateSelectedImageType(this.$route); 
    this.$nextTick(() => {
        // Force swiper update after component is fully mounted
        if (this.$refs.swiper) {
            this.$refs.swiper.$swiper.update();
        }
    });
    window.addEventListener('resize', this.handleResize);
  },

  beforeDestroy() {
    // Clean up resize listener
    window.removeEventListener('resize', this.handleResize);
},




  created() {

this.checkUserLoggedIn();
EventBus.$on('update-generating', this.updateGenerating);
  // Auto-select the image type based on the route
  const currentRoute = this.$route.path;
  if (currentRoute.includes('/agents/images')) {
    this.selectedImageType = 'images';
  } else if (currentRoute.includes('/agents/memes')) {
    this.selectedImageType = 'memes';
  } else if (currentRoute.includes('/agents/quotes')) { 
    this.selectedImageType = 'quotes';
  }

},
computed: {
        isLogg() {
            return this.$store.state.app.isLogged
    },
    areAllSelected() {
      return this.selectedImages.length === this.images.length && this.images.length > 0;
    },
  },
  
  methods: {
    
    checkUserLoggedIn() {
            const userData = localStorage.getItem("userData")

            if (userData == null || undefined) {
                this.$store.commit("app/SET_USER", false);

            } else {
                this.$store.commit("app/SET_USER", true);
            }

        },
        async searchInput() {
  // Check if prompt is empty
  if (!this.searchQuery) {
    this.$toast({
      component: ToastificationContent,
      props: {
        icon: 'XIcon',
        title: 'Input Required',
        text: 'Please enter a prompt before generating.',
        variant: 'danger',
      },
    });
    return;
  }

  // Handle non-logged in users first
  if (!this.isLogg) {
    if (this.requestCount >= this.maxRequests) {
      this.$toast({
        component: ToastificationContent,
        props: {
          icon: 'XIcon',
          title: 'Limit Reached',
          text: 'You have reached the maximum number of free requests. Please log in to continue.',
          variant: 'danger',
          action: {
            label: 'Log In',
            onClick: () => this.openLoginModal()
          }
        },
      });
      return;
    }

    // For non-logged in users within their free limit, proceed without usage check
    this.generating = true;
    this.showInfoLine = true;
    EventBus.$emit('generate-images', this.searchQuery, this.selectedTab, this.selectedImageType);
    this.requestCount++;
    return;
  }

  // For logged-in users, check usage limits
  try {
    const response = await axios.get(`${process.env.VUE_APP_BILLING_URL}usage/check`, {
      params: {
        userId: this.$store.state.app.userData?.userId,
        operationType: 'imageCreations'
      }
    });

    const { canProceed, usageDetails, message } = response.data;

    if (!canProceed) {
      this.$toast({
        component: ToastificationContent,
        props: {
          icon: 'AlertTriangleIcon',
          title: 'Usage Limit Reached',
          text: message || 'You have reached your usage limit.',
          variant: 'warning',
          action: {
            label: 'Upgrade Now',
            buttonVariant: 'success',
            onClick: () => this.$router.push('/plans')
          }
        },
      });
      return;
    }

    // If usage check passes, proceed with generation
    this.generating = true;
    this.showInfoLine = true;
    EventBus.$emit('generate-images', this.searchQuery, this.selectedTab, this.selectedImageType);

  } catch (error) {
    console.error('Error checking usage limits:', error);
    this.$toast({
      component: ToastificationContent,
      props: {
        icon: 'XIcon',
        title: 'Error',
        text: 'Unable to check usage limits. Please try again.',
        variant: 'danger',
      },
    });
  }
},

    
    resetSeed() {
    this.$bvModal.msgBoxConfirm('Are you sure you want to refresh? This will generate new variations.')
      .then(confirmed => {
        if (confirmed) {
          this.seed = null;
          localStorage.removeItem('savedSeed');
          this.showToast('Seed has been refreshed. New images will have a different variation.', 'info');
        }
      })
      .catch(err => {
        // Handle any errors here
      });
  },

  toggleBulkDelete() {
    this.$emit('delete-mode-change', !this.isDeleteMode);
  },

  confirmDelete() {
    this.$emit('confirm-delete');
  },
  suggestPrompt() {
      if (!this.prompts.length) {
        this.$toast({
          component: ToastificationContent,
          props: {
            icon: 'InfoIcon',
            title: 'No Prompts Available',
            text: 'There are no prompts available to suggest.',
            variant: 'info',
          },
        });
        return;
      }

      // Fetch the current prompt
      const prompt = this.prompts[this.currentPromptIndex];
      const currentPromptData = this.prompts[this.currentPromptIndex];
      // Set the searchQuery to the current prompt
      this.searchQuery = currentPromptData.prompt;
      this.savedSeed = currentPromptData.seed;
      localStorage.setItem('savedSeed', this.savedSeed);

      // Increment the prompt index for next suggestion
      this.currentPromptIndex = (this.currentPromptIndex + 1) % this.prompts.length;

      // Optionally, immediately trigger the search
      // this.searchInput();
    },

    updateGenerating(status) {
      this.generating = status;
    },
    selectTab(index) {
      this.tabs.forEach((tab, i) => {
        tab.active = i === index;
        if (tab.active) {
          this.selectedTab = tab.name;
        }
      });
      this.$emit('update:imageStyle', this.selectedTab); // Emit the selected tab name
    },
    openLoginModal() {
      this.$bvModal.show('modal-login');
    },
    handleLogin() {
      this.$store.commit("app/SET_USER", true);
      this.requestCount = 0; // Reset the request count on login
    },
    hideInfoLine() {
      this.showInfoLine = false; // Hide the info line when close button is clicked
    },
    updateSelectedImageType(route) {
      if (route.path.includes('/agent/images')) {
        this.selectedImageType = 'images';
      } else if (route.path.includes('/agent/memes')) {
        this.selectedImageType = 'memes';
      }
     else if (route.path.includes('/agent/illustrations')) {
        this.selectedImageType = 'illustrations';
      }
      else if (route.path.includes('/agent/quotes')) {
        this.selectedImageType = 'quotes';
      }
    },
    toggleBulkDelete() {
      this.isDeleteMode = !this.isDeleteMode;
      if (!this.isDeleteMode) {
        this.selectedImages = [];
      }
      // Emit an event to notify parent component about delete mode change
      this.$emit('delete-mode-change', this.isDeleteMode);
    },

    toggleSelectAll() {
      if (this.areAllSelected) {
        this.selectedImages = [];
      } else {
        this.selectedImages = this.images.map(img => img.id);
      }
    },

    toggleImageSelection(imageId) {
      const index = this.selectedImages.indexOf(imageId);
      if (index === -1) {
        this.selectedImages.push(imageId);
      } else {
        this.selectedImages.splice(index, 1);
      }
    },

    handleResize() {
        if (this.$refs.swiper) {
            this.$refs.swiper.$swiper.update();
        }
    },

    deleteSelectedImages() {
      // Emit event with selected image IDs for parent component to handle deletion
      this.$emit('delete-images', this.selectedImages);
      this.selectedImages = [];
      this.isDeleteMode = false;
      
      this.$toast({
        component: ToastificationContent,
        props: {
          icon: 'CheckIcon',
          title: 'Success',
          text: 'Selected images have been deleted.',
          variant: 'success',
        },
      });
    },
  },
};
</script>

<style scoped>
/* Adjust styles for a more compact layout */
.search-wrapper {
  width: 100%;
}
.idea-icon-wrapper {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  padding: 8px;
  border-radius: 50%;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.idea-icon-wrapper:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.bulk-delete-controls {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.delete-mode .image-tile {
  position: relative;
  cursor: pointer;
}

.delete-mode .image-tile::before {
  content: '';
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  width: 1.5rem;
  height: 1.5rem;
  border: 2px solid #fff;
  border-radius: 0.25rem;
  background-color: rgba(255, 255, 255, 0.5);
}

.delete-mode .image-tile.selected::before {
  background-color: #007bff;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23fff'%3E%3Cpath d='M20 6L9 17l-5-5'/%3E%3C/svg%3E");
  background-size: 1rem;
  background-position: center;
  background-repeat: no-repeat;
}

.idea-icon {
  width: 20px;
  height: 20px;
  color: #17007d;
  transition: all 0.3s ease;
  filter: opacity(0.6);
}

.idea-icon-wrapper:hover .idea-icon {
  filter: opacity(1);
}

.search-input {
  min-height: 40px;
}

.button-wrapper {
  display: flex;
  align-items: stretch;
  padding-top: 0px;
  padding-bottom: 0px;
}

.image-type-select {
  max-width: 100px; /* Adjust width as needed */
  margin-left: 10px; /* Add margin for better spacing */
}

.swiper-wrapper {
    display: flex;
    align-items: center;
    height: 100%;
    min-height: inherit; /* Inherit minimum height */
}

.swiper-slide {
    height: auto !important;
    min-height: inherit;
}

.swiper-slide {
    display: flex;
    align-items: center !important;
    justify-content: center !important;
    padding: auto !important; /* Adds padding inside each slide for spacing */
    box-sizing: border-box; /* Includes padding and border in the element's total width and height */
}

.swiper-container {
    width: 100%;
    min-height: 50px; 
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    -ms-overflow-style: none;
    margin-left: 0px !important;
    margin-right: 0px !important;
    margin-bottom: -27px !important;
    margin-top: 10px !important;
    position: relative; /* Add position relative */
    display: flex; /* Add flex display */
    align-items: center; /* Center items vertically */
}

.swiper-container::-webkit-scrollbar {
    display: none;  /* Hides scrollbar in WebKit browsers */
}

@media screen and (max-width: 768px) {
    .swiper-container {
      margin-bottom: -20px !important;    /* Further adjust for smaller screens */
        margin-top: 0px !important;     /* Adjust this value for mobile */
    }
}

/* Small mobile styles - typically 480px and below */
@media screen and (max-width: 480px) {
    .swiper-container {
      margin-bottom: -18px !important;    /* Further adjust for smaller screens */
        margin-top: 10px !important;       /* Further adjust for smaller screens */
    }
}

.button-margin{
  margin-right: 5px;
  margin: 0px;
}

.tab-button {
  font-size: 1rem !important;
  font-variant: Black !important;
    margin-bottom: 8px; /* Space between tags if they wrap to the next line */
    margin-top: 2px; 
    margin-left: 4px; 
    margin-right: 4px; 
    padding: 0.48571rem 0.71429rem !important;
    display: inline-flex; /* Use inline-flex to maintain inline behavior with flexbox capabilities */
    align-items: center !important;
    justify-content: center !important;
    white-space: nowrap; /* Keeps the tag text in a single line */
    text-overflow: ellipsis; /* Adds an ellipsis if the text is too long */
    overflow: hidden;
    border-radius: 20px; /* Rounded corners for aesthetic */
    border: 1px solid #ccc; /* Subtle border for definition */
    background-color: hsl(0, 0%, 100%); /* Background color */
    transition: all 0.2s ease-in-out; /* Smooth transition for hover effects */
}

.tab-button:hover, .tab-button.is-active {
    background-color: #0069d9; 
}

.upload-btn {
  padding: 12px 24px;
  font-size: 1.1rem;
  font-weight: 500;
  border-radius: 8px;
  margin-left:0rem;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.upload-btn:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.15);
}

/* Add pulse animation for extra attention */
@keyframes pulse {
  0% { box-shadow: 0 0 0 0 rgba(0,123,255,0.4); }
  70% { box-shadow: 0 0 0 10px rgba(0,123,255,0); }
  100% { box-shadow: 0 0 0 0 rgba(0,123,255,0); }
}

.upload-btn:not(:hover) {
  animation: pulse 2s infinite;
}

.b-button {
  border-radius: 0.25rem; /* Match button styles as per your design */
}
.ss-width {
    width: auto !important;

}
.form-control{
  border: 1px solid #212121
}
.custom-select{
  border: 1px solid #212121
}
.search-input::placeholder {
  color: rgb(141, 141, 141); /* Change this to your desired dark grey color */
}
.info-line {
  /* border-top: 1px solid #e0e0e0; */
  /* padding-bottom: 8px; */
  margin-bottom: 12px;
}

.info-icon {
  color: #17a2b8; /* Bootstrap info color */
}

.close-button {
  /* Remove default button styles */
  padding: 0;
  margin-left: auto;
  color: #6c6c6c;
}

.close-button:hover {
  color: #343a40; /* Darken color on hover */
}

.close-button .feather-icon {
  width: 15px;
  height: 15px;
}

.note-text {
  color: #000000; /* Bootstrap secondary text color */
  font-size: .95rem;
  padding-left: 3px;
}

/* Optional: Adjust the Info icon size and alignment */
.info-icon {
  width: 14px;
  height: 14px;
}

.like-icon {
  color: red;
  fill: red;
  margin-bottom: 2px;
}

@media (max-width: 768px) {
  
  .image-type-select{
    max-width: 30px;
}
.custom-select{
  border: 1px solid #212121
}
.form-control{
  border: 1px solid #212121;
}
.seed-controls {
  margin-top: 0em;
  margin-bottom: 1em;
  margin-left: 2px;
  display: flex;
  align-items: center;
}



.seed-controls .seed-info {
  margin-left: 1em;
  font-weight: bold;
}
.tab-button {
  font-size: 1rem !important;
    margin-bottom: 8px; /* Space between tags if they wrap to the next line */
    margin-top: 2px; 
    margin-left: 4px; 
    margin-right: 4px; 
    padding: 0.48571rem 0.71429rem !important;
    display: inline-flex; /* Use inline-flex to maintain inline behavior with flexbox capabilities */
    align-items: center !important;
    justify-content: center !important;
    white-space: nowrap; /* Keeps the tag text in a single line */
    text-overflow: ellipsis; /* Adds an ellipsis if the text is too long */
    overflow: hidden;
    border-radius: 20px; /* Rounded corners for aesthetic */
    border: 1px solid #ccc; /* Subtle border for definition */
    background-color: hsl(0, 0%, 100%); /* Background color */
    transition: all 0.2s ease-in-out; /* Smooth transition for hover effects */
}
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '~swiper/css/swiper.css';
</style>
